<!--
 * @Author: wangshuaiwei
 * @Date: 2022-03-31 10:25:51
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-04-06 09:13:19
 * @Description: 
-->
<template>
  <div>
    <div :style="{ height: $attrs['height'] }" ref="line"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    lineColor: {
      type: Array,
      defalut: ["#0BCC3B", "#F8B551", "#e136ff", "#0040ff", "#C490BF", "orange", "red", "blue", "green"],
    },
    axisLabel: {
      type: Boolean,
      defalut: true,
    },
    echartInfoName: {
      type: String,
    },
    echartsData: {
      type: Array,
      defalut: [],
    },
    lableOption: {
      type: Boolean,
    },
    yAxisName: {
      type: String,
    }
  },
  watch: {
    echartsData(a) {
      this.renderLine(a[0], a[1]);
    },
  },
  inheritAttrs: false,
  mounted() {
    this.renderLine(this.echartsData);
  },
  methods: {
    renderLine(xData, yData) {
      const line = this.$refs.line;
      const lineEcharts = this.$echarts.init(line);
      /** 字体 */
      let fontSize = (window.innerHeight / 1080) * 100 * 0.12;
      //let color = ["#0BCC3B", "#F8B551", "#e136ff", "#0040ff", "#C490BF", "orange", "red", "blue", "green"];
      const option = {
        color: this.lineColor,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter:'{a}<br />{b0}: {c0}'
        },
        // legend: {
        //   show: false,
        //   x: "right",
        //   data: [
        //     {
        //       name: "Line 1",
        //       textStyle: {
        //         color: "white",
        //       },
        //     },
        //     {
        //       name: "Line 2",
        //       textStyle: {
        //         color: "white",
        //       },
        //     },
        //   ],
        // },
        toolbox: {
          // feature: {
          //   saveAsImage: {}
          // }
        },
        grid: {
          left: "2%",
          right: "4%",
          bottom: "3%",
          top: "19%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: xData || [],
            axisLine: {
              lineStyle: {
                color: "#fff",
                opacity: 0.2,
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: fontSize, // 坐标轴文字大小
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.yAxisName,

            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                width: 1,
              },
            },
            axisTick: {
              show: false,
              length: 9,
              alignWithLabel: false,
              lineStyle: {
                color: "#AAA",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#65C6E7",
                opacity: 0.2,
                width: 1,
              },
            },
            axisLabel: {
              show: this.lableOption,
               textStyle: {
                fontSize: fontSize, // 坐标轴文字大小
              },
            },
          },
        ],
        series: [
          {
            name: this.echartInfoName,
            type: "line",
            // stack: "Total",
            smooth: true,
            // lineStyle: {
            //   width: 2,
            // },
            // showSymbosl: false,
            // areaStyle: {
            //   opacity: 0.8,
            //   color: "rgba(94, 233, 233,.3)",
            // },
            // emphasis: {
            //   focus: "series",
            // },
            // data: [140, 232, 101, 100, 0, 340, 250],
            data: yData || [],
          },
        ],
      };
      lineEcharts.setOption(option);
       window.addEventListener("resize", function() {
        lineEcharts.resize();
      });
    },
  },
};
</script>
